<template>
  <transition appear>
    <div class="bg-white w-11.5/12 rounded-xl my-5 mx-auto py-1 px-5 shadow-lg">
      <div class="flex justify-between items-center">
        <div class="mt-5 flex justify-start items-center cursor-pointer">
          <span
            @click="goBack"
            class="material-icons rounded-full bg-teal text-white"
          >
            chevron_left
          </span>
          <router-link
            class="font-bold text-teal mx-2"
            :to="{ name: `re-locums` }"
          >
            Back To Dashboard
          </router-link>
        </div>
        <div>
          <label class="text-teal" for="Location Filter"
            ><b>Location Filter: </b></label
          >
          <select
            @change="onLocationFilter"
            class="bg-lightGrey w-80 py-1 px-2 text-grey"
            name="Location Fiter"
          >
            <option :value="'all'">All</option>
            <option
              v-for="location in locations"
              :key="location.id"
              :value="location.id"
            >
              {{
                location.practice_name[0].toUpperCase() +
                location.practice_name.slice(1)
              }}
            </option>
          </select>
        </div>
      </div>

      <div class="bg-lightGrey rounded-xl w-12/12 my-5 mx-auto py-7 px-5">
        <div class="flex justify-between items-center">
          <h2 class="text-grey font-bold text-2xl">
            {{ monthString }} Sessions
          </h2>
          <div class="flex gap-x-2">
            <va-button
              @click="decrementMonth"
              class="w-56"
              icon="chevron_left"
              color="#00b5b5"
              text-color="white"
            >
              Previous Month
            </va-button>
            <va-button
              @click="incrementMonth"
              class="w-56"
              icon-right="chevron_right"
              color="#00b5b5"
              text-color="white"
            >
              Next Month
            </va-button>
          </div>
        </div>
        <div class="bg-lightGrey rounded-xl min-h-cardM mt-5">
          <div class="mt-5">
            <div class="text-teal flex gap-x-2 text-sm mb-1">
              <span
                @click="allSessionsFunc"
                class="
                  tab
                  cursor-pointer
                  inline-block
                  py-1
                  px-7
                  rounded-md
                  border-4 border-teal
                "
                :class="{ buttonActive: tableState === 'all' }"
              >
                All
              </span>
              <span
                @click="vacantSessionsFunc"
                class="
                  tab
                  cursor-pointer
                  inline-block
                  py-1
                  px-7
                  rounded-md
                  border-4 border-teal
                "
                :class="{ buttonActive: tableState === 'vacant' }"
              >
                Vacant
              </span>
              <span
                @click="filledSessionsFunc"
                class="
                  tab
                  cursor-pointer
                  inline-block
                  py-1
                  px-7
                  rounded-md
                  border-4 border-teal
                "
                :class="{ buttonActive: tableState === 'filled' }"
              >
                Filled
              </span>
            </div>
            <div
              :key="refreshTable"
              class="border-2 border-grey w-full px-x py-7 bg-white"
            >
              <!-- <div class="flex justify-center items-center h-64">
                        <span
                            class="material-icons flex align-center text-8xl text-grey"
                        >
                            event_busy
                        </span>
                        </div> -->
              <!-- <div
                class="w-11.5/12 my-3 rounded-xl mx-auto bg-lightGrey py-7 px-5 flex"
              >
                <div class="date-container w-full md:w-1/5">
                  <h2 class="font-bold text-grey text-xl my-5">
                    Tuesday 1st March
                  </h2>
                  <p class="font-bold text-teal my-5 mt-10">No Sessions</p>
                </div>
                <div class="w-full flex-wrap md:w-3/5 flex items-center gap-5">
                  <div class="flex my-auto items-center">
                    <span
                      class="material-icons text-teal border-2 text-center h-11 w-11 border-teal text-4xl rounded-full cursor-pointer"
                    >
                      add
                    </span>
                    <h2 class="text-grey font-bold text-xl ml-3">
                      Add Session
                    </h2>
                  </div>
                </div>
              </div> -->
              <!-- <div class="flex justify-center items-center h-64">
                        <span
                            class="material-icons flex align-center text-8xl text-grey"
                        >
                            event_busy
                        </span>
                        </div> -->
              <div
                v-for="day in daysInMonth"
                :key="day.dayNumber"
                class="
                  w-11.5/12
                  my-3
                  rounded-xl
                  mx-auto
                  bg-lightGrey
                  py-7
                  px-5
                  flex
                "
              >
                <div class="date-container w-full md:w-1/5">
                  <h2 class="font-bold text-grey text-xl my-5">
                    {{ day.dayString }}
                  </h2>
                  <p
                    class="font-bold text-teal my-5 mt-10"
                    v-if="day.sessions.length === 0"
                  >
                    No Sessions
                  </p>
                </div>
                <div
                  v-if="day.sessions?.length > 0"
                  class="w-auto flex-wrap md:w-3/5 flex items-center gap-5"
                >
                  <div
                    v-for="session in day.sessions"
                    :key="session.id"
                    class="
                      session-card
                      border-2
                      flex flex-col
                      border-grey
                      rounded-xl
                      py-4
                      px-6
                    "
                  >
                    <div class="flex session-row">
                      <div>
                        <div class="font-bold">
                          <div>
                            <span class="text-grey"
                              >{{ format12(session.start_time) }} -
                              {{ session.end_date }}({{
                                format12(session.end_time)
                              }})</span
                            >
                          </div>
                        </div>
                        <div class="font-bold">
                          <div class="flex gap-x-2 w-full">
                            <span class="text-teal"> Name: </span>
                            <span class="text-grey"> {{ session.name }} </span>
                          </div>
                        </div>
                        <div class="font-bold">
                          <div class="flex gap-x-2 w-1/2">
                            <span class="text-teal"> Position: </span>
                            <span v-if="session.locums[0]" class="text-grey">
                              {{ session.locums[0]?.roles[0].name }}
                            </span>
                            <span v-else class="text-grey"> Unavailable </span>
                          </div>
                        </div>
                        <div class="font-bold flex">
                          <div class="flex gap-x-2 w-1/2">
                            <span class="text-teal"> Location: </span>
                            <span class="text-grey">
                              {{ session.location }}
                            </span>
                          </div>
                        </div>
                        <div class="font-bold flex">
                          <div class="flex gap-x-2 w-1/2">
                            <span class="text-teal"> Rate: </span>
                            <span class="text-grey">
                              £{{ session.rate }}/{{ session.unit }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div v-if="tableState == 'filled'" class="w-2/4">
                        <h3 v-if="session.locums.length != 0" class="text-grey font-semibold text-center">
                          Filled By:
                        </h3>
                        <!-- <div class="position flex items-center justify-center">
                      <span class="material-icons text-8xl text-teal my-4">
                        person_add
                      </span>
                    </div> -->
                        <!-- Profile card for daily profile -->
                        <div
                          v-if="session.locums.length != 0"
                          class="
                            mt-2
                            profile-card
                            rounded-xl
                            py-3
                            px-2
                            relative
                            text-grey
                            bg-white
                            flex flex-row
                            items-center
                            justify-start
                          "
                        >
                          <div
                            class="
                              rounded-full
                              w-16
                              h-16
                              border-2 border-teal
                              p-2
                              flex
                              justify-center
                              items-center
                            "
                          >
                            <span
                              class="
                                material-icons
                                w-full
                                h-full
                                flex
                                items-center
                                pl-2
                                text-white text-3xl
                                rounded-full
                                bg-orange
                              "
                            >
                              person_filled
                            </span>
                          </div>
                          <div class="ml-2 text-sm">
                            <h3 class="font-bold mt-2">
                              {{ session.locums[0]?.profile?.first_name }}&nbsp;
                              {{ session.locums[0]?.profile?.last_name }}
                            </h3>
                            <p>
                              <span class="font-bold">
                                {{
                                  session.locums[0]?.profile?.primary_role
                                }}</span
                              >
                            </p>
                            <p>
                              <span class="font-bold">{{
                                session.locums[0]?.profile?.mobile_phone
                              }}</span>
                            </p>
                            <p>
                              <span
                                @click="
                                  openNotes(session.locums[0]?.locum_notes)
                                "
                                class="font-bold cursor-pointer"
                                ><u>ShowNotes</u></span
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="flex my-auto items-center cursor-pointer"
                  v-if="tableState === 'all'"
                  @click="showAddSession = true"
                >
                  <span
                    class="
                      material-icons
                      text-teal
                      border-2
                      text-center
                      h-11
                      w-11
                      border-teal
                      text-4xl
                      rounded-full
                    "
                  >
                    add
                  </span>
                  <h2 class="text-grey font-bold text-xl ml-3">Add Session</h2>
                </div>
              </div>
              <div v-if="daysInMonth.length < 1" 
              class="
                  w-11.5/12
                  my-3
                  rounded-xl
                  mx-auto
                  bg-lightGrey
                  py-7
                  px-5
                  flex
                "
              >
              <b class="capitalize">{{ this.tableState }}</b>&nbsp; sessions not available.
              </div>
            </div>
          </div>
        </div>
      </div>

      <base-modal
        v-model:show="showAddSession"
        @confirm="submit"
        :showCancel="false"
        :showConfirm="false"
      >
        <template #title>Add New Session</template>
        <template #content>
          <div class="px-5 py-0">
            <div class="mb-1">
              Session Name:
              <input
                type="text"
                maxlength="50"
                placeholder="Please enter session name"
                v-model="createSessionPayload.name"
                class="
                  p-1
                  border-2 border-transparent
                  px-1
                  mb-1
                  mx-1
                  inline-block
                  cursor-pointer
                  font-bold
                  text-teal
                  placeholder-teal
                "
                :class="{
                  'border-red':
                    validate && v$.createSessionPayload.name.$errors.length,
                }"
                ref="sessionName"
              />
            </div>
            <div class="rounded-xl w-full -my-3">
              <div class="leading-5">
                I need
                <select
                  v-model="createSessionPayload.role"
                  class="
                    focus:border-teal
                    p-1
                    border-2 border-transparent
                    px-1
                    mb-1
                    mx-1
                    inline-block
                    cursor-pointer
                    font-bold
                    text-teal
                  "
                  :class="{
                    'border-red':
                      validate && v$.createSessionPayload.role.$errors.length
                  }"
                >
                  <option :value="null">Role</option>
                  <option v-for="role in roles" :key="role.code" :value="role.code">
                    {{ role.label }}
                  </option>
                </select>
                from
                <input
                  type="date"
                  ref="start_date"
                  v-model="createSessionPayload.start_date"
                  placeholder="Start Date"
                  class="
                    focus:border-teal
                    p-1
                    border-2 border-transparent
                    px-1
                    mb-1
                    mx-1
                    inline-block
                    cursor-pointer
                    font-bold
                    text-teal
                  "
                  :class="{
                    'border-red':
                      validate &&
                      v$.createSessionPayload.start_date.$errors.length,
                  }"
                />
                at
                <input
                  type="time"
                  v-model="createSessionPayload.start_time"
                  placeholder="Start Time"
                  class="
                    focus:border-teal
                    p-1
                    border-2 border-transparent
                    px-1
                    mb-1
                    mx-1
                    inline-block
                    cursor-pointer
                    font-bold
                    text-teal
                  "
                  :class="{
                    'border-red':
                      validate &&
                      v$.createSessionPayload.start_time.$errors.length,
                  }"
                />
                until
                <input
                  type="date"
                  placeholder="End Date"
                  v-model="createSessionPayload.end_date"
                  class="
                    focus:border-teal
                    p-1
                    border-2 border-transparent
                    px-1
                    mb-1
                    mx-1
                    inline-block
                    cursor-pointer
                    font-bold
                    text-teal
                  "
                  :class="{
                    'border-red':
                      validate &&
                      v$.createSessionPayload.end_date.$errors.length,
                  }"
                />
                at
                <input
                  type="time"
                  v-model="createSessionPayload.end_time"
                  placeholder="Start Date"
                  class="
                    focus:border-teal
                    p-1
                    border-2 border-transparent
                    px-1
                    mb-1
                    mx-1
                    inline-block
                    cursor-pointer
                    font-bold
                    text-teal
                  "
                  :class="{
                    'border-red':
                      validate &&
                      v$.createSessionPayload.end_time.$errors.length,
                  }"
                />
              </div>
              <div class="my-1">
                The hiring rate is
                <i class="font-bold text-teal placeholder-teal">£</i>
                <input
                  type="number"
                  min="0"
                  placeholder="Wage"
                  v-model="createSessionPayload.rate"
                  class="
                    focus:border-teal
                    p-1
                    border-2 border-transparent
                    px-1
                    mb-1
                    mx-1
                    w-24
                    inline-block
                    cursor-pointer
                    font-bold
                    text-teal
                    placeholder-teal
                  "
                  :class="{
                    'border-red':
                      validate && v$.createSessionPayload.rate.$errors.length,
                  }"
                />
                per
                <select
                  v-model="createSessionPayload.unit"
                  class="
                    focus:border-teal
                    p-1
                    border-2 border-transparent
                    px-1
                    mb-1
                    mx-1
                    inline-block
                    cursor-pointer
                    font-bold
                    text-teal
                  "
                  :class="{
                    'border-red':
                      validate && v$.createSessionPayload.unit.$errors.length,
                  }"
                >
                  <option :value="null">Time Unit</option>
                  <option value="hourly">Hour</option>
                  <option
                    v-if="createSessionPayload.role == 29"
                    value="session"
                  >
                    Session
                  </option>
                </select>
              </div>
              <div class="my-1">
                The position is located at
                <select
                  v-model="createSessionPayload.location"
                  class="
                    focus:border-teal
                    p-1
                    border-2 border-transparent
                    px-1
                    mb-1
                    mx-1
                    inline-block
                    cursor-pointer
                    font-bold
                    text-teal
                  "
                  :class="{
                    'border-red':
                      validate &&
                      v$.createSessionPayload.location.$errors.length,
                  }"
                >
                  <option :value="null">Location</option>
                  <option
                    v-for="location in locations"
                    :key="location.id"
                    :value="
                      location.practice_name[0].toUpperCase() +
                      location.practice_name.slice(1)
                    "
                  >
                    {{
                      location.practice_name[0].toUpperCase() +
                      location.practice_name.slice(1)
                    }}
                  </option>
                </select>
              </div>
              <div
                v-if="
                  validate === true &&
                  (v$.createSessionPayload.role.$errors.length ||
                    v$.createSessionPayload.name.$errors.length ||
                    v$.createSessionPayload.start_date.$errors.length ||
                    v$.createSessionPayload.end_date.$errors.length ||
                    v$.createSessionPayload.start_time.$errors.length ||
                    v$.createSessionPayload.end_time.$errors.length ||
                    v$.createSessionPayload.rate.$errors.length ||
                    v$.createSessionPayload.unit.$errors.length ||
                    v$.createSessionPayload.location.$errors.length)
                "
              >
                <div class="input-errors border-2 border-red py-2 px-4">
                  <div class="error-msg text-red">{{ sessionError }}</div>
                </div>
              </div>
              <div class="flex justify-end">
                <BaseButton @click.prevent="createSession">
                  Confirm
                </BaseButton>
              </div>
            </div>
          </div>
        </template>
      </base-modal>

      <base-modal
        v-model:show="showNotes"
        @confirm="submit"
        :showCancel="false"
        :showConfirm="false"
      >
        <template #title>Notes:</template>
        <template #content>
          <div v-for="notes in currentNotes" :key="notes.id">
            &#9745;{{ notes.note }}
          </div>
        </template>
      </base-modal>
    </div>
  </transition>
</template>

<script>
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

import BaseButton from "@/components/ui/baseComponents/BaseButton.vue";
import Swal from "sweetalert2";

export default {
  name: "locumSessions",
  components: {
    BaseButton,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      monthToShow: new Date(),
      monthString: moment(new Date()).format("MMMM YYYY"),
      filter: "all",
      dropdownOptions: [
        {
          label: "UK",
          value: "uk",
          type: "input",
        },
        {
          label: "UK",
          value: "uk",
          type: "input",
        },
      ],
      daysInMonth: [],
      allSessions: [],
      noSessions: [],
      vacantSessions: [],
      filledSessions: [],
      refreshTable: 0,
      tableState: "all",

      showAddSession: false,

      validate: true,
      createSessionPayload: {
        practice: 1,
        role: null,
        name: null,
        quantity: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        rate: null,
        unit: null,
        location: null,
      },
      masks: {
        weekdays: "WWW",
      },

      monthlySessions: [],
      roles: [],
      locations: [],
      showNotes: false,
      currentNotes: [],
      sessionError : "Missing Input Fields.",
    };
  },
  validations() {
    return {
      createSessionPayload: {
        name: {
          required: helpers.withMessage("Session name is required", required),
        },
        role: {
          required: helpers.withMessage("Role field is required", required),
        },
        quantity: {
          required: helpers.withMessage("Quantity field is required", required),
        },
        start_date: {
          required: helpers.withMessage(
            "Start-date field is required",
            required
          ),
          minValue: helpers.withMessage("Required (Date should not be less than today.)", value => value >= new Date((new Date()).valueOf() - 1000*60*60*24).toISOString()),
        },
        end_date: {
          required: helpers.withMessage("End-date field is required", required),
          minValue: helpers.withMessage("Required (Date should not be less than today.)", value => value >= new Date((new Date()).valueOf() - 1000*60*60*24).toISOString()),
        },
        start_time: {
          required: helpers.withMessage(
            "Start-time field is required",
            required
          ),
        },
        end_time: {
          required: helpers.withMessage("End-time field is required", required),
        },
        rate: {
          required: helpers.withMessage("Wage field is required", required),
        },
        unit: {
          required: helpers.withMessage("Unit field is required", required),
        },
        location: {
          required: helpers.withMessage("Location field is required", required),
        },
      },
    };
  },
  async created() {
    await this.fetchMonthlySessions();
    await this.fetchRoles();
    await this.fetchLocations();

    this.getRoles();
    this.getMonthlySessions();
    this.getDaysInMonth();
    this.getLocations();
  },
  methods: {
    async incrementMonth() {
      this.tableState = "all";
      this.monthToShow = new Date(
        this.monthToShow.getFullYear(),
        this.monthToShow.getMonth() + 1,
        1
      );
      this.getDaysInMonth();
      this.monthString = moment(this.monthToShow).format("MMMM YYYY");

      if (Number(this.month) + 1 == 13) {
        this.month = 1;
        this.year = Number(this.year) + 1;
      } else {
        this.month = Number(this.month) + 1;
      }
      if (this.month / 10 < 1) {
        this.month = "0" + this.month;
      }
      await this.fetchMonthlySessions();
      this.getMonthlySessions();
      this.getDaysInMonth();
    },
    async decrementMonth() {
      this.tableState = "all";
      this.monthToShow = new Date(
        this.monthToShow.getFullYear(),
        this.monthToShow.getMonth() - 1,
        1
      );
      this.getDaysInMonth();
      this.monthString = moment(this.monthToShow).format("MMMM YYYY");

      if (Number(this.month) - 1 == 0) {
        this.month = 12;
        this.year = Number(this.year) - 1;
      } else {
        this.month = Number(this.month) - 1;
      }
      if (this.month / 10 < 1) {
        this.month = "0" + this.month;
      }
      await this.fetchMonthlySessions();
      this.getMonthlySessions();
      this.getDaysInMonth();
    },
    format12(time) {
      let newTime;
      let hours = time.slice(0, 2);
      let minutes = time.slice(3, 5);
      if (hours > 12) {
        newTime = hours % 12;
        newTime += `:${minutes} pm`;
      } else {
        newTime = `${hours}:${minutes} am`;
      }
      return newTime;
    },
    async fetchMonthlySessions() {
      try {
        if (!this.year || !this.month) {
          const date = new Date().toISOString().split("-");
          this.year = date[0];
          this.month = date[1];
        }
        await this.$store.dispatch("ReLocums/fetchMonthlySessions", {
          date: `${this.year}-${this.month}`,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    getMonthlySessions() {
      this.monthlySessions = [
        ...this.$store.getters["ReLocums/getMonthlySessions"],
      ];
      return this.$store.getters["ReLocums/getMonthlySessions"];
    },
    async createSession() {
      this.validate = true;
      const validated = await this.v$.$validate();
      if (validated === 21) {
        await this.$store.dispatch(
          "ReLocums/createSession",
          this.createSessionPayload
        );
        this.createSessionPayload = {
          practice: 1,
          role: null,
          name: null,
          quantity: null,
          start_date: null,
          end_date: null,
          start_time: null,
          end_time: null,
          rate: null,
          unit: null,
          location: null,
        };
        this.validate = false;
        this.showAddSession = false;
        Swal.fire({
          title: "Done",
          text: "Session created successfully!",
          icon: "success",
        });
      }

      /////

      await this.fetchMonthlySessions();
      this.getMonthlySessions();

      this.getDaysInMonth();

      /////

      setTimeout(() => {
        this.validate = false;
      }, 5000);
    },
    getDaysInMonth() {
      var daysInMonth = moment(this.monthToShow).daysInMonth();
      var arrDays = [];

      while (daysInMonth) {
        let dayString =
          moment(this.monthToShow).date(daysInMonth).format("dddd") +
          " " +
          moment(this.monthToShow).date(daysInMonth).format("Do") +
          " " +
          moment(this.monthToShow).date(daysInMonth).format("MMMM");
        let dayNumber = moment(this.monthToShow).date(daysInMonth).format("D");
        let sessions = this.monthlySessions.filter((session) => {
          return Number(session.start_date.split("-")[2]) == Number(dayNumber);
        });
        arrDays.push({ dayString, dayNumber, sessions });
        daysInMonth--;
      }
      this.daysInMonth = arrDays;
      this.daysInMonth.sort((a, b) => a.dayNumber - b.dayNumber);

      this.allSessions = this.daysInMonth;
      this.noSessions = this.daysInMonth.filter(
        (day) => day.sessions.length === 0
      );

      this.vacantSessions = this.daysInMonth;
      this.vacantSessions = this.daysInMonth.filter(
        (session) => session.sessions[0]?.locums_count < 1
      );

      this.filledSessions = this.daysInMonth;
      this.filledSessions = this.daysInMonth.filter(
        (session) => session.sessions[0]?.locums_count > 0
      );

      return arrDays;
    },
    goBack() {
      this.$router.push({ name: "re-locums" });
    },
    async fetchRoles() {
      try {
        await this.$store.dispatch("ReLocums/fetchRoles");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    getRoles() {
      console.log("roles: ", this.roles)
      this.roles = this.$store.getters["ReLocums/getRoles"];
      return this.$store.getters["ReLocums/getRoles"];
    },
    async fetchLocations() {
      try {
        await this.$store.dispatch("ReLocums/fetchLocations");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    getLocations() {
      this.locations = this.$store.getters["ReLocums/getLocations"];
      return this.locations;
    },
    async onLocationFilter(event) {
      if (event.target.value == "all") {
        await this.fetchMonthlySessions();
        this.getMonthlySessions();
        this.getDaysInMonth();
        console.log("Fetched all sessions:");
      } else {
        this.fetchMonthlyFilterSessions(event.target.value);
      }
    },
    async fetchMonthlyFilterSessions(data) {
      try {
        if (!this.year || !this.month) {
          const date = new Date().toISOString().split("-");
          this.year = date[0];
          this.month = date[1];
        }
        await this.$store.dispatch("ReLocums/fetchMonthlyFilterSessions", {
          date: `${this.year}-${this.month}`,
          location: parseInt(data),
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.getMonthlyFilterSessions();
    },
    getMonthlyFilterSessions() {
      this.monthlySessions = [
        ...this.$store.getters["ReLocums/getMonthlyFilterSessions"],
      ];
      this.getDaysInMonth();
      return this.$store.getters["ReLocums/getMonthlyFilterSessions"];
    },
    filledSessionsFunc() {
      this.daysInMonth = this.filledSessions;
      this.refreshTable++;
      this.tableState = "filled";
    },
    vacantSessionsFunc() {
      this.daysInMonth = this.vacantSessions;
      this.refreshTable++;
      this.tableState = "vacant";
    },
    allSessionsFunc() {
      this.daysInMonth = this.allSessions;
      this.refreshTable++;
      this.tableState = "all";
    },
    monthFormat(value) {
      if (value < 10) {
        return "0" + value;
      } else {
        return value;
      }
    },
    openNotes(notes) {
      this.showNotes = true;
      this.currentNotes = notes;
      console.log(notes, "notes");
    },
  },
};
</script>

<style scoped>
.filterDropDown .vs__dropdown-toggle,
.filterDropDown .vs__dropdown-menu {
  border-radius: 0.5rem;
  background: lightgray;
  padding-left: 0.2rem;
}
.filterDropDown .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}
#filterDropDown .filterDropDown .vs__clear,
#filterDropDown .filterDropDown .vs__open-indicator {
  fill: black;
}
.filterDropDown {
  min-width: 300px;
  border: none;
  background: lightgray;
}
.session-row {
  min-width: 400px;
  max-width: 400px;
}
.buttonActive {
  border-color: teal;
  background-color: teal;
  color: white;
  padding-top: 2px;
}
</style>
